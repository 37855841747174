import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import PetCardCompact from "./scheduler/PetCardCompact";
import ZoneBadge from "./ZoneBadge";
import Timeblock from "./Timeblock";
import { DropdownList } from "react-widgets";
import _ from "lodash";
import history from "../../actions/history";
import TimePicker from "rc-time-picker";
import MapAppointmentRow from "./MapAppointmentRow";
import MapAppointmentRowUnstacked from "./MapAppointmentRowUnstacked";
import BreakRow from "./BreakRow";
import ShiftMarker from "./ShiftMarker";
import axios from "../../services/network.js";

const combineDateTime = (date, time, tz) => {
  let dateFormatted = moment(date).format("ddd MMM D YYYY");
  return moment(`${dateFormatted} ${time}`).format("ddd MMM D YYYY H:mm");
};

class SitterPreview extends Component {
  state = {
    mounted: false,
    valid: true,
    appointments: [],
    editGhost: false,
    switch: false,
    newSitter: null,
    stacked: true
  };

  componentWillReceiveProps(props) {
    if (props.appointments) {
      this.setState({
        appointments: props.appointments
      });
    }
  }

  componentDidMount() {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setState({ ...this.state, mounted: true });
      });
    });
    if (this.props.appointments) {
      this.setState({
        appointments: this.props.appointments
      });
    }
  }

  changeTime = (newTime, appt) => {
    let appointments = this.state.appointments.slice();
    let changeAppts = appointments.map(a => {
      if (a._id === appt._id) {
        let time = combineDateTime(a.window_start_time, newTime);
        let diff = moment(time).diff(a.scheduled_start_time, "minutes");
        let idle = appt.idle_time ? appt.idle_time : 0;
        appt.idle_time = idle + diff;
        appt.scheduled_start_time = time;
        appt.special = true;
      }
      return a;
    });
    this.setState({ appointments: changeAppts });
  };

  dontSubmit = () => {
    this.setState({ valid: false });
  };

  allowSubmit = () => {
    this.setState({ valid: true });
  };

  editEnRoute = appt => {
    let appointments = this.state.appointments.slice();
    let changeAppts = appointments.map(a => {
      if (a._id === appt._id) {
        a.en_route = !a.en_route;
        a.special = true;
      }
      return a;
    });
    this.setState({ appointments: changeAppts });
  };

  toggleOpen = appt => {
    let appointments = this.state.appointments.slice();
    let changeAppts = appointments.map(a => {
      if (a._id === appt._id) {
        a.open = a.open ? false : true;
      }
      return a;
    });
    this.setState({ appointments: changeAppts });
  };

  renderTeamMember = () => {
    if (!this.props.sitter) {
      return null;
    }
    let isToday = moment(this.props.selectedDate).isSame(moment(), "day");
    let stati = [1, 2, 3, 4, 5];
    let ourVisits = this.state.appointments.filter(
      a =>
        a.sitter &&
        a.sitter._id === this.props.sitter._id &&
        stati.includes(a.appointment_status.id)
    );
    if (!this.state.stacked) {
      if (this.props.sitter.break_start && this.props.sitter.break_end) {
        ourVisits.push({
          ...this.props.sitter,
          scheduled_start_time: moment(
            this.props.sitter.break_start,
            "hh:mm a"
          ).toDate(),
          break: true
        });
      }

      return ourVisits
        .sort(
          (a, b) =>
            moment(a.scheduled_start_time) - moment(b.scheduled_start_time)
        )
        .map((a, i) => {
          if (!a.break) {
            return (
              <MapAppointmentRowUnstacked
                appointment={a}
                length={this.state.appointments.length}
                changeTime={this.changeTime}
                highlightAppointmentOnMap={this.props.highlightAppointmentOnMap}
                showPreview={this.props.showPreview}
                dontSubmit={this.dontSubmit}
                allowSubmit={this.allowSubmit}
                editEnRoute={this.editEnRoute}
                toggleOpen={this.toggleOpen}
                timeZone={this.props.timeZone}
                lateness={
                  isToday && this.props.sitter.lateness
                    ? this.props.sitter.lateness
                    : 0
                }
                key={`resultsRow${a._id}`}
              />
            );
          } else {
            return (
              <BreakRow
                stacked={false}
                breakId={this.props.sitter.break_id}
                day={this.props.selectedDate}
                breakStart={this.props.sitter.break_start}
                breakEnd={this.props.sitter.break_end}
                timeZone={this.props.timeZone}
                sitter={this.props.sitter}
                removeBreak={this.props.removeBreak}
                showSpinner={this.props.showSpinner}
                stopSpinner={this.props.stopSpinner}
                updateBreak={this.props.updateBreak}
              />
            );
          }
        });
    }

    return ourVisits
      .sort(
        (a, b) =>
          moment(a.scheduled_start_time) - moment(b.scheduled_start_time)
      )
      .map((a, i) => {
        let z;
        if (a.appointment_status.id === 5) z = 1000;
        if (a.appointment_status.id === 4) z = 1200;
        if (a.appointment_status.id === 2) z = 1000;
        if (a.special) z = 1500;

        let style = {
          zIndex: z
        };
        return (
          <div style={style}>
            <MapAppointmentRow
              appointment={a}
              length={this.state.appointments.length}
              changeTime={this.changeTime}
              highlightAppointmentOnMap={this.props.highlightAppointmentOnMap}
              showPreview={this.props.showPreview}
              dontSubmit={this.dontSubmit}
              allowSubmit={this.allowSubmit}
              editEnRoute={this.editEnRoute}
              toggleOpen={this.toggleOpen}
              timeZone={this.props.timeZone}
              lateness={
                isToday && this.props.sitter.lateness
                  ? this.props.sitter.lateness
                  : 0
              }
              key={`resultsRow${a._id}`}
            />
          </div>
        );
      });
  };

  renderBreaks = () => {
    if (!this.state.stacked) return null;
    return (
      <BreakRow
        stacked={true}
        breakId={this.props.sitter.break_id}
        day={this.props.selectedDate}
        breakStart={this.props.sitter.break_start}
        breakEnd={this.props.sitter.break_end}
        timeZone={this.props.timeZone}
        sitter={this.props.sitter}
        removeBreak={this.props.removeBreak}
        showSpinner={this.props.showSpinner}
        stopSpinner={this.props.stopSpinner}
        updateBreak={this.props.updateBreak}
      />
    );
  };

  renderFloatingBreak = () => {
    if (!this.state.stacked) return null;
    if (
      !this.props.sitter.floating_break_start_time ||
      !this.props.sitter.floating_break_end_time
    )
      return null;
    return (
      <BreakRow
        day={this.props.selectedDate}
        breakStart={moment(
          this.props.sitter.floating_break_start_time,
          "hh:mm a"
        ).format("ddd MMM D YYYY H:mm")}
        breakEnd={moment(
          this.props.sitter.floating_break_end_time,
          "hh:mm a"
        ).format("ddd MMM D YYYY H:mm")}
      />
    );
  };

  renderShiftStart = () => {
    if (!this.state.stacked) return null;
    return (
      <ShiftMarker
        time="start"
        sitter={this.props.sitter}
        timeZone={this.props.timeZone}
        day={this.props.selectedDate}
      />
    );
  };

  renderShiftEnd = () => {
    if (!this.state.stacked) return null;
    return (
      <ShiftMarker
        time="end"
        sitter={this.props.sitter}
        timeZone={this.props.timeZone}
        day={this.props.selectedDate}
      />
    );
  };

  changeStart = e => {
    let value = e.target.value;
    this.props.changeStart({ id: this.props.sitter._id, value });
  };

  changeEnd = e => {
    let value = e.target.value;
    this.props.changeEnd({ id: this.props.sitter._id, value });
  };

  removeShift = () => {
    this.props.removeShift(this.props.sitter._id);
  };

  renderTimes = () => {
    if (!this.state.stacked) return null;
    let times = [];
    let count = -90;
    for (
      let m = moment()
        .hour(9)
        .minute(0);
      m < moment().hour(19);
      m.add(1, "hour")
    ) {
      count += 90;
      times.push({ time: m.format("hh:mm"), count: count });
    }
    return times.map(t => {
      let style = {
        position: "absolute",
        top: t.count - 15
      };
      return (
        <span style={style} key={`timeColumn${t.count}`}>
          {t.time}
        </span>
      );
    });
  };

  changeSitter = value => {
    this.props.moveVisits({ from: this.props.sitter._id, to: value._id });
    this.setState({ newSitter: value });
  };

  getShift = () => {
    if (!this.props.sitter) return null;
    if (!this.props.sitter.shift)
      return <span>Team Member Not Scheduled In Homebase</span>;
    return (
      <span>
        Shift:{" "}
        {moment(this.props.sitter.shift.StartTimeLocalized).format("hh:mm a")} -{" "}
        {moment(this.props.sitter.shift.EndTimeLocalized).format("hh:mm a")}
      </span>
    );
  };

  getStack = () => {
    return (
      <div className="stackerz">
        <label>Stacked View</label>
        <input
          type="checkbox"
          checked={this.state.stacked}
          onChange={() => this.setState({ stacked: !this.state.stacked })}
        />
      </div>
    );
  };

  getSwitch = () => {
    if (!this.props.sitter) return null;
    if (!this.state.switch) {
      return (
        <button
          className="btn btn-secondary moveVisits"
          onClick={() => this.setState({ switch: !this.state.switch })}
        >
          Move Visits
        </button>
      );
    } else {
      return (
        <div className="movingVisits">
          <DropdownList
            data={this.props.sitters.map(s => {
              return {
                _id: s._id,
                full_name: s.contact.full_name
              };
            })}
            defaultValue="Choose TM:"
            onChange={val => this.changeSitter(val)}
            textField={"full_name"}
            // dropUp
          />
          <div>{this.state.newSitter && this.state.newSitter.full_name}</div>
        </div>
      );
    }
  };

  renderAddBreak = () => {
    return (
      <button
        className="btn btn-primary moveVisits"
        onClick={() => this.addBreak()}
      >
        Add Break
      </button>
    );
  };

  addBreak = async () => {
    try {
      this.props.showSpinner();
      let date = moment(this.props.selectedDate).format("YYYY-MM-DD");
      let { data } = await axios.post(
        `${process.env.REACT_APP_API_URI}/admin/addStaffBreak/${this.props.sitter._id}?date=${date}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`
          }
        }
      );
      this.props.addBreak(this.props.sitter, data);
      this.props.stopSpinner();
    } catch (e) {
      this.props.stopSpinner();
      console.log("errro", e);
      alert("error!");
    }
  };

  saveAppointments = async () => {
    let needsSaving = this.props.appointments.find(a => a.special);
    if (!this.state.valid || !needsSaving) return;
    let appts = this.state.appointments.filter(
      a => a.sitter && a.sitter._id === this.props.sitter._id
    );
    this.props.saveAppointments(appts);
  };

  addAppt = e => {
    e.stopPropagation();
    let preview;

    if (this.props.previewedAppointment) {
      preview = this.props.previewedAppointment;
    } 

    if (preview) {
      this.props.assignSitter(this.props.sitter, preview);
    } else {
      alert('No appointment selected.');
    }
  };

  closePanes = () => {
    let appointments = this.state.appointments.slice();
    let changeAppts = appointments.map(a => {
      a.open = false;
      return a;
    });
    this.setState({ appointments: changeAppts });
  };

  render() {
    let needsSaving = this.props.appointments.find(a => a.special);
    let style = {
      width: this.state.mounted ? "48%" : 0,
      transition: ".25s all ease",
      height: "600px"
    };

    return (
      <div
        className="sitterPreview"
        style={style}
        onDoubleClick={e => this.addAppt(e)}
        onClick={() => this.closePanes()}
      >
        <Link to={`/staff/${this.props.sitter._id}`}>
          <h3>
            {this.props.sitter.contact.full_name}{" "}
            {this.props.sitter.full_time ? (
              <i className="fa fa-clock-o" />
            ) : null}{" "}
            {this.props.sitter.zones.map(z => (
              <ZoneBadge
                size="tiny"
                zone={z}
                key={`aZone${this.props.sitter._id}${z.id}`}
              />
            ))}
          </h3>
        </Link>
        <button
          className={`btn btn-primary specialSaveBtn ${
            this.state.valid && needsSaving ? "specialSaveBtnValid" : ""
          }`}
          onClick={() => this.saveAppointments()}
        >
          Save
        </button>
        {this.getShift()}
        {this.getSwitch()}
        {this.renderAddBreak()}
        {this.getStack()}
        <div className="apptCont">
          <div className="timesLeft">{this.renderTimes()}</div>
          <div className="timesRight">
            {this.renderTeamMember()}
            {this.props.sitter && this.props.sitter.break_start
              ? this.renderBreaks()
              : null}
            {/*this.props.sitter && this.props.sitter.floating_break ? this.renderFloatingBreak() : null*/}
            {this.renderShiftStart()}
            {this.renderShiftEnd()}
          </div>
        </div>
      </div>
    );
  }
}

export default SitterPreview;
