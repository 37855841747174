import React, { Component } from "react";
import moment from "moment-timezone";
import Tooltip from "rc-tooltip";
import {
  AdminCommentIcon,
  ClientCommentIcon,
  ScheduleAppointmentForm,
  ZoneBadge
} from "../../";
import { DragSource } from "react-dnd";
import { Link } from "react-router-dom";

const statusColors = {
  // 'completed': '#FFB575',
  completed: "#9DD7CC",
  "in-progress": "#F8787A",
  approved: "#AFD47B",
  pending: "#FED866",
  "change-requested": "#AB8BE8",
  selected: "#91CDC2"
};

class PetCardCompact extends Component {

  static defaultProps = {
    handleSelectAppointment: () => {},
    showDate: false,
    showAddress: false
  };

  render() {
    const {
      appointment,
      drawerOpen,
      handleScheduleAppointment,
      handleSelectAppointment,
      isTransitional,
      onClickAppointmentDetail,
      onScheduleFail,
      onScheduleSuccess,
      scheduledTimeFieldValue,
      selected,
      selectedDate,
      showAddress,
      showDate,
      sitterId
    } = this.props;

    const {
      appointment_status,
      client_notes,
      contact,
      duration_in_minutes,
      home,
      _id,
      recurrence,
      scheduled_start_time,
      window_start_time,
      window_end_time,
      sitter,
      appointment_type,
      addons
    } = appointment;
    const appointment_pets = appointment.pets || appointment.appointment_pets;

    const premium_add_ons = addons?.filter(a => a.id > 8);

    // const findStatus = ()
    let status;
    if (appointment_status.id === 1) {
      status = "pending";
    }
    if (appointment_status.id === 2) {
      status = "approved";
    }
    if (appointment_status.id === 3) {
      status = "change-requested";
    }
    if (appointment_status.id === 4) {
      status = "in-progress";
    }
    if (appointment_status.id === 5) {
      status = "completed";
    }
    if (appointment_status.id === 6) {
      status = "rejected";
    }
    if (appointment_status.id === 7) {
      status = "canceled";
    }

    let consultation;
    if (appointment_type.id === 8) consultation = "consultation";

    // const status = appointment_status.id === 1 ? 'pending' : 'approved'
    status.replace(" ", "-");
    let startTime = moment(window_start_time).add(30, "minutes");
    if (scheduled_start_time) {
      startTime = moment(scheduled_start_time);
    }

    const petNames =
      appointment_pets && appointment_pets.length
        ? appointment_pets.map(pet => pet.name).join(", ")
        : "Pet Names Unavailable";

    let circleClass = "";
    const homeClass = home.home_class;
    const petClasses = appointment_pets.map(pet => pet.pet_class);
    if (homeClass === "Red" || petClasses.includes("Red")) {
      circleClass = " red-border";
    } else if (homeClass === "Yellow" || petClasses.includes("Yellow")) {
      circleClass = " yellow-border";
    }

    let maleGenderPreference =
      appointment.contact.gender_preference === "No Males";

    return (
      <div
        className="pet-card"
        onClick={e => {
          e.stopPropagation();
          onClickAppointmentDetail(this.props.appointment._id);
        }}
      >
        <div
          className={`pet-card-detail pet-card-detail-compact ${appointment_status.name.toLowerCase()}${
            selected ? " selected" : ""
          }${drawerOpen ? " open" : ""} ${consultation ? consultation : ""}`}
        >
          <div className="pet-card-annotations">
            <div className="left-annotations">
              {recurrence && recurrence.is_recurring ? (
                <Tooltip
                  placement="top"
                  overlay={
                    <div>
                      {recurrence.recurrence_type === "weekly"
                        ? "Weekly"
                        : "Monthly"}
                    </div>
                  }
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <span className="fa fa-clock-o recurring-icon" />
                  </div>
                </Tooltip>
              ) : null}

              {home.admin_client_notes ? (
                <Tooltip
                  placement="top"
                  overlay={<div>{home.admin_client_notes}</div>}
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <AdminCommentIcon
                      color={
                        selected
                          ? statusColors["selected"]
                          : statusColors[status]
                      }
                      height="20"
                      width="18"
                    />
                  </div>
                </Tooltip>
              ) : null}
              {client_notes ? (
                <Tooltip
                  placement="top"
                  overlay={<div>{client_notes}</div>}
                  mouseEnterDelay={0.2}
                  mouseLeaveDelay={0.1}
                  overlayClassName="annotation-tooltip"
                >
                  <div>
                    <ClientCommentIcon
                      color={
                        selected
                          ? statusColors["selected"]
                          : statusColors[status]
                      }
                      height="20"
                      width="18"
                    />
                  </div>
                </Tooltip>
              ) : null}
            </div>
            <div className="right-annotations">
              {showDate ? (
                <p className="appointment-detail">
                  {startTime.format("M/D/YY")}
                </p>
              ) : null}
              {/*<ZoneBadge size="tiny" zone={home.zone} />*/}
            </div>
          </div>

          <div className="pet-card-main">
            <div className="pet-image-wrapper">
              {appointment_pets.length ? (
                <div
                  className={`pet-img${circleClass}`}
                  style={{
                    backgroundImage: `url(${appointment_pets[0].image_url})`
                  }}
                ></div>
              ) : null}
            </div>
            <div className="client-info-wrapper">
              <p className="client-name">
                <Link to={`/client/${appointment.client_id}`}>
                  {contact.full_name} {maleGenderPreference ? "*" : ""}
                </Link>
              </p>
              <p className="pet-name">{petNames}</p>

              <p className="scheduled-time">
                {scheduled_start_time
                  ? moment(scheduled_start_time).format("h:mmA")
                  : "TBD"}{" "}
              </p>
            </div>

            {showAddress ? (
              <div className="client-address-wrapper">
                <p>{home.address.address_line_1}</p>
                {home.address.address_line_2 ? (
                  <p>{home.address.address_line_2}</p>
                ) : null}
                <p>
                  {home.address.city}, {home.address.state}{" "}
                  {home.address.zip_code}
                </p>
              </div>
            ) : null}
          </div>

          <div className="pet-card-times">
            <div className="pet-card-time-cell left">
              <p className="pet-card-time-label">TIME WINDOW</p>
              <p className="appointment-detail">
                {moment(window_start_time).format("h:mmA")}-
                {moment(window_end_time).format("h:mmA")}
              </p>
            </div>

            <div className="pet-card-time-cell right">
              <p className="pet-card-time-label">TYPE</p>
              <p className="appointment-detail">
                {this.props.appointment.appointment_type.id === 12
                  ? "Concierge"
                  : `${duration_in_minutes} Min`}
              </p>
              {
                premium_add_ons && premium_add_ons.length ? (
                  <div className="premium-add-ons">
                    {premium_add_ons.map(addon => (
                      <p key={addon.id} className="appointment-detail">
                        {addon.name}
                      </p>
                    ))}
                  </div>
                ) : null
              }
            </div>
          </div>

          <div
            className={`appointment-detail-link ${status}${
              selected ? " selected" : ""
            }`}
          >
            <p>Status: {this.props.appointment.appointment_status.name}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PetCardCompact;
